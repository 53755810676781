<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient
                            :client_id="client_id"
                            :siggys="original_signature_ids.length"
                            :form_data_id="record_id"
                            @getClient="getClient"
                        /><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button type="button" @click="signature_ids=[]" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <p>This ‘Informed Consent’ document is intended to provide information about the counseling services and business practices provided by {{ company.company_name }}. It also contains information about the Health Insurance Portability and Accountability Act (HIPAA), a federal law that provides privacy protections and patient rights about the use and disclosure of your Protected Health Information (PHI). Please review the information below and ask your clinician if you have any questions. Your signature indicates an acknowledgment and agreement between you, your clinician, and {{ company.company_name }}.</p>
                <h3>The Therapy Process</h3>
                <p>Therapy is a collaborative process. The type, extent, and duration of services I will receive will be determined through an initial assessment and ongoing relationship and discussions with my clinician. Your clinician is a trained professional engaged in providing mental health care services directly to you, the client. I understand there are both risks and benefits associated with these services. Risks may include uncomfortable and unpleasant feelings due to the nature of the discussions. Benefits may include reduction in feelings of distress, better self-awareness, and resolutions to specific problems discussed. I understand the outcome of my treatment largely depends on my willingness to engage in the process. I understand I may withdraw from treatment at any time and to consult with my clinician if questions or doubts arise.</p>
                <h3>Goals, Purposes, and Techniques of Therapy</h3>
                <p>Multiple intervention types may be used as treatment during your experience. As therapy progresses, techniques, goals and purposes my change. Please consult with your clinician if any questions or concerns arise.</p>
                <h3>Appointments and Cancellations</h3><p>Appointments can be scheduled through direct contact with {{ company.company_name }} during normal operating business hours. In the event you need to cancel your appointment, please abide by {{ company.company_name }} policies to avoid additional fees. Please assure you understand {{ company.company_name }}'s policies for appointment requests, and cancellations to assure you are following these policies. Please reach out to {{ company.company_name }} for any questions.</p>
                <h3>Payment for Services</h3>
                <p>You may be required to pay for services and other fees. You will be provided with a breakdown of these fees prior to your appointment. If utilizing insurance, confirm with your insurance that part or all of these fees are covered. {{ company.company_name }} will provide a breakdown of all acceptable methods of payment.</p>
                <h3>Confidentiality</h3>
                <p>Conversations between a clinician and a client are confidential. No information will be released unless the client requests in writing for information to be shared, or it is mandated by law including but not limited to the following possible exceptions:</p>
                <ul>
                    <li>Child abuse</li>
                    <li>Sexual abuse</li>
                    <li>Homicidal or suicidal thoughts or attempts</li>
                    <li>Abuse of the disabled or elderly</li>
                    <li>Abuse of patients in mental health facilities</li>
                    <li>Child custody cases</li>
                    <li>Criminal activities</li>
                    <li>Information stated on a subpoena</li>
                    <li>A lawsuit brought by the client against the therapist/filing of a complaint with licensing board or regulatory authority</li>
                    <li>AIDS/HIV infection and possible transmission</li>
                </ul>
                <p>If your clinician needs to consult with other professionals in their fields of expertise in order to provide the best treatment for you they may share clinical information about you but will not provide your name or identifying demographic information.</p>
                <p>If information must be disclosed your clinician will follow all HIPAA guidelines. If you have any questions pertaining to confidentiality you should bring them to the attention of the clinician.</p>
                <p>In the event a clinician runs into you outside of the office, they will not acknowledge you first. Your right to privacy and confidentiality is of the utmost importance. However, if you acknowledge the clinician first, they will speak with you briefly in only appropriate lengths and formats outside of the office.</p>
                <h3>Duty to Warn</h3>
                <p>In the event the clinician has been led to believe that the client is a danger to themselves or others the clinician has consent to warn the individuals at risk as well as to involve any other parties necessary to mitigate risk including but not limited to law enforcement and medical personnel. This consent will expire upon termination of therapy.</p>
                <h3>Records</h3>
                <p>Your clinician is required to keep records regarding your treatment. These records provide evidence of services and progression of therapy. These records are stored within BirchNotes and are protected and secured.</p>
                <h3>Consent to Treatment</h3>
                <p>Signing below indicates you have read and understand this agreement and agree to abide by its terms. You are voluntarily agreeing to allow for mental health services to be provided by a clinician at {{ company.company_name }}. You are confirming you have been given the opportunity to ask questions on any portions of this agreement that are unclear to you, the client.</p>

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit</button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />

                </div>
                <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <SignConvertPdfModal />            
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex'
    import { merge } from 'lodash'

    export default {
        name: 'InformedConsentForTherapy',
        props: {
            record_id: {
                type: [String, Number],
                required : false,
                default : 0
            },
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
            }
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            showCreatePdfModal(newVal){
                if(window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if(newVal == false){this.closeModalOverlay()}
            },
        }
    }
</script>